<template>
  <b-container class="dashboad bg-light page-wrapper">
    <b-row>
      <b-col cols="12">
        <h3> Custom Select (Ember)</h3>
      </b-col>
      <b-col cols="12" class="mb-4">
        <custom-select :loading="bLoading" v-model="oSelectedObjectFirst" :options="aOptionDataFirst" groupingValues="accounts" groupingLabel="label" label="label"></custom-select>
      </b-col>
    </b-row>

    <b-row>
      <b-col cols="12">
        <h3> Grouped twice</h3>
      </b-col>
      <b-col cols="12" class="mb-4">
        <custom-select
          :loading="bLoading"
          v-model="oSelectedObjectSecond"
          :options="aOptionDataSecond"
          groupingValues="accounts"
          groupingLabel="label"
          groupingValuesSecondary="groupAccounts"
          groupingLabelSecondary="label"
          label="label"
          :sortGroupLabel="false"
        >
        </custom-select>
      </b-col>
    </b-row>

    <b-row>
    <b-col cols="12">
      <h3> Result</h3>
    </b-col>
    <b-col cols="6" class="mb-4">
      {{oSelectedObjectFirst}}
    </b-col>
    <b-col cols="6" class="mb-4">
      {{oSelectedObjectSecond}}
    </b-col>
    </b-row>
  </b-container>
</template>

<script>
import axios from 'axios'
import CustomSelect from '@/components/common/CustomSelect'

export default {
  name: 'VueselectExperiment',
  components: { CustomSelect },
  data () {
    return {
      bLoading: true,
      oSelectedObjectFirst: {},
      aOptionDataFirst: [],
      oSelectedObjectSecond: {},
      aOptionDataSecond: []
    }
  },
  computed: {
  },
  methods: {
    async loadAccounts () {
      this.bLoading = true

      await axios.get(`${process.env.VUE_APP_ROOT_API}/accounts/groups/for/multiselect?account_type=income,expense`)
        .then(response => {
          const aApiData = response.data.data
          const aAccountData = []

          aAccountData.incomeGroups = aApiData.filter(item => item.type === 'income')
          aAccountData.incomeAccounts = aAccountData.incomeGroups.map(item => item.accounts).reduce(function (aTmp, aAccount) { return aTmp.concat(aAccount) })

          aAccountData.expenseGroups = aApiData.filter(item => item.type === 'expense')
          aAccountData.expenseAccounts = aAccountData.expenseGroups.map(item => item.accounts).reduce(function (aTmp, aAccount) { return aTmp.concat(aAccount) })

          this.aOptionDataFirst = aAccountData.incomeGroups

          this.aOptionDataSecond = [
            { label: 'Income', accounts: [], groupAccounts: aAccountData.incomeGroups },
            { label: 'Expense', accounts: [], groupAccounts: aAccountData.expenseGroups }
          ]
        })
        .catch(error => {
          console.error(error)
        })
      this.bLoading = false
    }
  },
  created () {
    this.loadAccounts()
  }
}
</script>
